.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


/**
 * ==============================================
 * Dot Stretching
 * ==============================================
 */

.snippet {
  width: 100%;
}

.examples .snippet:before {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5px;
  content: attr(data-title);
  font-size: 0.75rem;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  color: white;
  background-color: rgb(255, 25, 100);
  border-radius: 0.25rem 0 0.25rem 0;
}

.stage {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
  margin: 0 -5%;
  overflow: hidden;
}

.filter-contrast {
  filter: contrast(5);
  background-color: white;
}
.dot-stretching {
  position: relative;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #fb8500;
  background-color: #212353;
  background-color: #fb8500;
  transform: scale(1.25, 1.25);
  animation: dotStretching 1.5s infinite ease-in-out;

  animation-direction: alternate;
}

.dot-stretching::before,
.dot-stretching::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-stretching::before {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #fb8500;
  background-color: #fb8500;
  animation: dotStretchingBefore 2s infinite ease-in-out;
}

.dot-stretching::after {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  color: #fb8500;
  background-color: #fb8500;
  animation: dotStretchingAfter 2s infinite ease-in-out;
}

@keyframes dotStretching {
  0% {
    /* transform: translate(0) scale(0.7, 0.7); */
    background-color: #fb8500;
  }

  33% {
    /* transform: translate(-35px) scale(1, 1); */
    /* background-color: #fb8500; */
    background-color: #fb8500;
  }
  66% {
    /* transform: translate(35px) scale(1, 1); */
    background-color: #fb8500;
    background-color: #212353;
    /* background-color: #212353; */
  }
  100% {
    /* transform: translate(0) scale(0.7, 0.7); */
    /* background-color: #fb8500; */
    background-color: #212353;
  }
}

@keyframes dotStretchingBefore {
  0% {
    /* transform: translate(0) scale(0.7, 0.7); */
    background-color: #fb8500;
    background-color: #212353;
  }

  33% {
    transform: translate(-35px) scale(1, 1);
    background-color: #212353;
  }
  66% {
    transform: translate(35px) scale(1, 1);
    background-color: #fb8500;
  }
  100% {
    transform: translate(0) scale(0.7, 0.7);
    background-color: #212353;
    background-color: #fb8500;
  }
}

@keyframes dotStretchingAfter {
  0% {
    /* transform: translate(0) scale(0.7, 0.7); */
    background-color: #212353;
  }
  33% {
    transform: translate(35px) scale(1, 1);
    background-color: #212353;
  }
  66% {
    transform: translate(-35px) scale(1, 1);
    background-color: #fb8500;
  }
  100% {
    /* transform: translate(0px) scale(0.7, 0.7); */
    background-color: #fb8500;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Russo One';
  src: local('Russo One'), url("./fonts/RussoOne-Regular.ttf") format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
   'embedded-opentype', and 'svg' */
}